<template>
    <div>
        <!-- Navigation -->
        <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav" >
            <div class="container">
            <router-link to="/" class="navbar-brand">MoezzaDev</router-link>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                Menu
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a href="#" v-scroll-to="'#about'" class="nav-link" >About</a>
                </li>
                <li class="nav-item">
                    <router-link to="/Producs" class="nav-link" >Our Producs</router-link>
                </li>
                <li class="nav-item">
                    <a href="#" v-scroll-to="'#contact'" class="nav-link" >Contact</a>
                </li>
                </ul>
            </div>
            </div>
        </nav>

        <!-- Page Header -->
        <header class="masthead" style="background-image: url('img/home-bg.jpg'); margin-bottom: 100px;">
            <div class="overlay" style="opacity:.2;"></div>
            <div class="waves">
                <img src="img/waves.png" alt="">
            </div>
            <div class="header_image">
                <div class="header_art">
                    <img src="img/art.png" alt="">
                </div>
            </div>
            <div class="container">
            <div class="row" >
                <div class="col-lg-8 col-md-10 mx-auto" >
                <div class="site-heading" >
                    <h1 class="brand"><b>MoezzaDev</b></h1>
                    <span class="subheading" style="text-shadow: 0 0 0 #ffffff, 0 0 10px #000000;">Our Inspirations Will Change Your Life</span>
                </div>
                </div>
            </div>
            </div>
        </header>

        <!-- Main Content -->
        <div class="container" id="about" style="padding-top:100px;">
            <div class="row">
            <div class="col-lg-8 col-md-10 mx-auto">
                <div class="post-preview">
                <!-- <a href="post.html"> -->
                    <h2 class="post-title">
                    MoezzaDev Software Company
                    </h2>
                    <h3 class="post-subtitle">
                    we are software and indie game developer based on Indonesia, want to expose our local uniqueness
                    </h3>
                <!-- </a> -->
                <p class="post-meta">East Java, Indonesia. September , 2020</p>
                </div>

                <hr>
                <!-- Pager -->
                <!-- <div class="clearfix">
                <a class="btn btn-primary float-right" href="#">Older Posts &rarr;</a>
                </div> -->
            </div>
            </div>
        </div>

        <vueper-slides style="padding-top:100px;">
            <vueper-slide v-for="(slide, i) in slides" :key="i" :title="slide.title" :content="slide.content" />
        </vueper-slides>

        <div class="container" style="padding-top:100px;" id="contact">
            <div>
            <h2 class="post-title">Contact Us</h2>
            </div>
            <div class="form" style="margin:30px;">
                <!-- <div id="sendmessage">Your message has been sent. Thank you!</div> -->
                <!-- <div id="errormessage"></div> -->
                <div class="row">
                    <div class="col-md-6"><a href="mailto: moezza.develop@gmail.com" style="text-transform:none; color:#fc3503;"><i class="fa fa-envelope"></i> moezza.develop@gmail.com </a><hr class="hrsub"></div>
                    <div class="col-md-6"><a href="tel: +6281326711418" style="text-transform:none; color:#fc3503;"><i class="fa fa-phone"></i> +6281326711418 </a><hr class="hrsub"></div>
                </div>
                <div class="row">
                    <div class="col-md-6"><a href="https://twitter.com/intent/tweet?screen_name=MoezzaDev" target="_blank" style="text-transform:none; color:#fc3503;"><i class="fab fa-twitter"></i> @MoezzaDev </a><hr class="hrsub"></div>
                    <div class="col-md-6"><a href="https://www.instagram.com/moezzadev/" target="_blank" style="text-transform:none; color:#fc3503;"><i class="fab fa-instagram"></i> moezzadev </a><hr class="hrsub"></div>
                </div>
            </div>
            <hr>
        </div>

        <div class="container"  style="padding-top:100px;">
            <div>
            <h2 class="post-title">send us your suggestions</h2>
            </div>
            <div class="form" style="margin:30px;">
                <div v-show="show" style="color:#ff0055;">thank you for your sugestion</div>
                <!-- <div id="errormessage"></div> -->
                <!-- <form action="" method="post" role="form" class="contactForm"> -->
                    <!-- <div class="form-group">
                    <input v-model="form.email" type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email">
                    <div class="validation"></div>
                    </div> -->
                    <div class="form-group">
                    <input v-model="form.subject" type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject">
                    <div class="validation"></div>
                    </div>
                    <div class="form-group">
                    <textarea v-model="form.text" class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                    <div class="validation"></div>
                    </div>
                    <div class="text-center"><button v-on:click="onSubmitMail()" class="btn" style="background:#FC3503; color:#fff;" type="submit">Send Message</button></div>
                <!-- </form> -->
            </div>
        </div>

        <!-- Footer -->
        <footer class="footer">
            <div class="container">
            <div class="row" style="margin-top:100px;">
                <div class="col-lg-8 col-md-10 mx-auto">
                <ul class="list-inline text-center">
                    <li class="list-inline-item">
                    <a href="https://twitter.com/MoezzaDev" target="_blank">
                        <span class="fa-stack fa-lg">
                        <i class="fas fa-circle fa-stack-2x" style="color:black;"></i>
                        <i class="fab fa-twitter fa-stack-1x fa-inverse"></i>
                        </span>
                    </a>
                    </li>
                    <li class="list-inline-item">
                    <a href="https://www.youtube.com/channel/UClB1_-7G_obUYWG7vhM3QRQ" target="_blank">
                        <span class="fa-stack fa-lg">
                        <i class="fas fa-circle fa-stack-2x" style="color:black;"></i>
                        <i class="fab fa-youtube fa-stack-1x fa-inverse"></i>
                        </span>
                    </a>
                    </li>
                    <li class="list-inline-item">
                    <a href="https://www.instagram.com/moezzadev/" target="_blank">
                        <span class="fa-stack fa-lg">
                        <i class="fas fa-circle fa-stack-2x" style="color:black;"></i>
                        <i class="fab fa-instagram fa-stack-1x fa-inverse"></i>
                        </span>
                    </a>
                    </li>
                    <li class="list-inline-item">
                    <a href="https://github.com/MoezzaDev-Software" target="_blank">
                        <span class="fa-stack fa-lg">
                        <i class="fas fa-circle fa-stack-2x" style="color:black;"></i>
                        <i class="fab fa-github fa-stack-1x fa-inverse"></i>
                        </span>
                    </a>
                    </li>
                </ul>
                <br>
                <p class="copyright" style="color:#ffffff;">Copyright &copy; MoezzaDev 2020</p>
                </div>
            </div>
            </div>
        </footer>
        <!-- <div style="position:relative">
            <div class="art_side">
                <img src="img/art_side.png" alt="">
            </div>
        </div> -->
        
    </div>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'

    export default {
        props:{
            app_name : String
        },
        name: 'Home',

        data () {
            return {
                form: {
                    mailto: 'moezza.develop@gmail.com',
                    subject: '',
                    email: '',
                    text: ''
                },
                show: false,
                slides: [
                    {
                    title: 'Slide #1',
                    content: '<img src="img/home-bg 2.jpg" alt="">'
                    },
                    {
                    title: 'Slide #2',
                    content: 'Slide content.'
                    }
                ]
            }
        },
        methods: {
            onSubmitMail () {
                // alert(JSON.stringify(this.form));
                window.open('mailto:'+this.form.mailto+'?subject='+this.form.subject+'&body='+this.form.text+'');

                /* Reset our form values */
                this.form.subject = '';
                this.form.email = '';
                this.form.text = '';
                /* Trick to reset/clear native browser form validation state */
                this.show = true;
            }
        },

        components: { VueperSlides, VueperSlide },
    }
</script>

<style>
.hrsub{
    border-top: 1px solid #ff3700;
    width: 50%;
}
.post-title{
    font-size: 40px;
    font-weight: bold;
    color: #ff0055;
    text-transform: capitalize;
}
.brand{
    color:#42153e;
    text-shadow: 0 0 0 #ffffff, 0 0 15px #000000;
    -webkit-text-stroke: 2px white;
}
.footer{
    margin-top: 100px;
    height: 300px;
    background-image: linear-gradient(to right, #ff3700, #C30F42 , #C82553, #ff0087); 
    border-top-right-radius: 40%;
    border-top-left-radius: 40%;
}
@media only screen and (min-width: 900px) {
  .header_image{
    position: relative;
    margin: 0px 5vw 0px 5vw;
  }
  .header_art{
    position: absolute;
    right: 1vw;
  }
  .header_art img{
    height: 600px;
  }

  /* .art_side {
    height: 900px;
    position: absolute;
    bottom: 0;
  }
  .art_side img{
    height: 900px;
  } */
  .waves img{
    position: absolute;
    top:50px;
    right: 0;
    height: 500px;
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .header_image{
    position: relative;
  }
  .header_art{
    position: absolute;
    top:80px;
    left: 50%;
    transform: translate(-50%, -0%);
  }
  .header_art img{
    height: 350px;
  }
  .art_side img{
    display: none;
  }
  .waves img{
    position: absolute;
    top:200px;
    right: 0;
    height: 350px;
    width: 300%;
  }
}
</style>