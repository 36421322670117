<template>
    <div id="app">
        <router-view app_name="MoezzaDev Software Company"></router-view>    
    </div>
</template>

<script>
    export default {
        name: 'App'
    }
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
}

nav-item :active{
  color: black;
}
</style>
